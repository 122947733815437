import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import { useQuery } from "react-query";
import CategoryWrapper from "../features/CategoryWrapper";
import PageWrapper from "../ui/PageWrapper";
import { getData } from "../utils/getData";
import {
  CollectionDataT,
  DataT,
  PortfolioCategoryStriperT,
  PortfolioCategoryT,
} from "../utils/types";

const DigitalHuman: FC = () => {
  const { strapiPortfolioCategories } = useStaticQuery(query);
  const portfolioCategories: CollectionDataT<PortfolioCategoryStriperT> =
    strapiPortfolioCategories;

  const categoryItem = portfolioCategories.data.find(
    (item) => item.attributes.navigation_id == "dh"
  );

  const getContent = (): JSX.Element | null => {
    if (categoryItem) {
      const { data } = useQuery("dh-page", () =>
        getData<DataT<PortfolioCategoryT>>({
          apiUrl: `${process.env.API_URL}/portfolio-categories/${categoryItem.id}?populate=deep`,
        })
      );

      const sectionsData = data && data.data.attributes.portfolios;

      return (
        <CategoryWrapper
          coverData={categoryItem.attributes}
          sectionsData={sectionsData}
        />
      );
    }
    return null;
  };

  return <PageWrapper>{getContent()}</PageWrapper>;
};

export default DigitalHuman;

const query = graphql`
  query {
    strapiPortfolioCategories {
      data {
        attributes {
          navigation_id
          text {
            highlight_color
            id
            paragraph_size
            text
            text_color
            width
            x_offset
            y_offset
          }
          render {
            id
            desktop {
              data {
                attributes {
                  url
                }
              }
            }
          }
        }
        id
      }
    }
  }
`;
